.ErrorPage {
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,100,300,500');

  font-size: 100%;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  height: 100vh;

  .button {
    font-weight: 300;
    font-size: 1.2em;
    text-decoration: none;
    border: 1px solid #efefef;
    padding: 0.5em;
    border-radius: 3px;
    transition: all 0.3s linear;
  }

  .button:hover {
    border-color: black;
    color: black;
  }

  p {
    font-size: 2em;
    text-align: center;
    font-weight: 100;
  }

  h1 {
    text-align: center;
    font-size: 15em;
    font-weight: 100;
  }
}
