.react-pdf__Page {
  display: flex;
  justify-content: center;
}

.ant-modal-body {
  padding: 12px;
}

.ant-btn {
  line-height: 0.5;
}
